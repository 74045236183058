import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import './contact.css'
const Contact = ()=>{
      const form = useRef();
      const [name, setName]  = useState('')
      const sendEmail = (e) => {
          e.preventDefault();
     // service_id, templte_id and public key will get from Emailjs website when you create account and add template service and email service 
         emailjs.sendForm('service_v2ppp0l', 'template_lp9s9gh', form.current, 
         'bVSWNfJX4nxD1M84W')
     .then((result) => {
         console.log(result.text);
     }, (error) => {
         console.log(error.text);
     });
     e.target.reset()
     alert("Submit successfully")
 };
    return(
        <div className="bg-white-color">
            <Header />
            <div className="contact-common-m-t">
            </div>
            <section className="common-padding">
                <section className="contact-common-width">
                    <section className="contact-form">
                        <h1 className="subtitle-color-black-contact common-heading">Please Send me Details</h1>
                        <div class="container">
                            <form ref={form} onSubmit={sendEmail}>
                            <div className="common-form">
                                    <label for="fname" className="label-style">Name</label>
                                    <input type="text" id="fname" name="user_name" placeholder="Your name.."/>
                            </div>
                            <div className="common-form">
                                    <label for="email" className="label-style">Email address</label>
                                    <input type="text" id="email" name="user_email" placeholder="Your Email.." />
                            </div>
                            <div className="common-form">
                                    <label for="phone" className="label-style">Phone Number</label>
                                    <input type="text" id="phone" name="user_phone" placeholder="Phone Number.." />
                            </div>
                            <div className="common-form">
                                    <label for="subject" className="label-style">Message</label>
                                    <textarea id="subject" name="message" placeholder="Write something.. (Please Share Your Mobile Number)" style={{height:'100px'}}></textarea>
                            </div>
                            <div className="common-form submitbtn-alignment">
                                    <button type="submit" value="Submit" className="submitbtn">Submit</button>
                            </div>
                            </form>
                        </div>
                </section>
                
                </section>
                <div className="padding-section">
                </div>
            </section>
           <Footer />
        </div>
    )
}

export default Contact;