import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slider.css'
import A from '../../assests/images/logo/zee.png'
import B from '../../assests/images/logo/tataiis.png'
import C from '../../assests/images/logo/skill.png'
import D from '../../assests/images/logo/mpt.png'
import E from '../../assests/images/logo/mfn.jpg'
import F from '../../assests/images/imglist/6.png'
const MutipleSlidesPerView  = () =>  {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    // cssEase: "linear",
    className:'img-center',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      <div className="img-center">
         <img src={A} />
      </div>
      <div className="img-center">
      <img src={B} />
      </div>
      <div className="img-center">
      <img src={C} />
      </div>
      <div className="img-center">
      <img src={D} />
      </div>
      <div className="img-center">
        <img src={E} />
      </div>
    </Slider>
  );
}

export default MutipleSlidesPerView;