import React from "react";
import './aboutcompany.css'
const AboutCompany = ()=>{
    return(
        <div className="bg-about-section">
            <div className="about-bg-logo-section">
                  {/* <section className="bg-img-about">
                  </section> */}
                 <section className="bg-img-text">
                    <h1 className="subtitle common-heading">About The digicast solutions</h1>
                    <p className="about-subtitle">DIGICAST Solutions is a new-age organization at the 
                            forefront of visual communication and amplification, catering to the dynamic needs of diverse industries. Our primary objective is to provide top-notch solutions that not only meet our client’s demands but also enhance the 
                        value they gain from their resources while minimizing costs.
                    </p>
                    <p className="about-subtitle">
                    In today's fast-paced world, we recognize the power of visual communication. We firmly believe that impactful messages, strong brand awareness, and engaging target audiences are all achieved through effective visual communication. Leveraging the expertise of our skilled professionals, we employ cutting-edge technologies and innovative techniques to deliver visually captivating and meaningful communication solutions that leave a lasting impression on viewers.

                    </p>
                    <p className="about-subtitle">
                    Further more, our commitment extends to optimizing our clients' resources and costs related to their communication strategies. Our approach involves efficient resource allocation, creative problem-solving, and cost-effective solutions that deliver maximum value to our clients. By thoroughly analyzing our clients' needs, customizing our solutions to their specific requirements, and surpassing their expectations, we ensure exceptional results.
                    </p>
                 </section>
                

            </div>
        </div>
    )
}

export default AboutCompany;