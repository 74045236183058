import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import '../../ServicesPage/services.css'
import live from '../../../assests/images/imglist/Q.jpg'
import event from '../../../assests/images/imglist/event.JPG'
const LiveEvent = () => {
    const listItem = [

        { label: 'Custom Stage Design That Captivates: We transform venues with stunning, custom-designed stages tailored to your event’s theme and vision. Whether it’s an elegant corporate gala, an exciting product launch, or a large-scale concert, we create an immersive environment that captivates your audience from the moment they enter.' },
        { label: 'Seamless Branding: Your brand deserves to shine, and we make sure it does. From branded stages and banners to digital displays and interactive elements, we integrate your brand’s identity into every aspect of the event. Our goal is to ensure your message resonates with attendees, building strong, lasting connections.' },
        { label: 'Flawless Production: Our experienced production team delivers a polished, professional event every time. From lighting, sound, and visuals to on-stage coordination and live-streaming, we handle every technical aspect with expertise, so your event runs smoothly and without a hitch.' }
    ]
    const subListItem = [

        { label: 'Custom Stage Design & Set Building' },
        { label: 'Integrated Branding Solutions' },
        { label: 'Full-Service Event Production' },
        { label: 'Audio-Visual Management' },
        { label: 'Live Streaming & Broadcast Solutions' },
        { label: 'On-Site Coordination & Logistics' }
    ]


    return (
        <div>
            <Header />
            <div className="common-m-t">
            </div>
            <section className="service-common-bg common-padding">
                <div className="common-width">
                    <h1 className="service-common-title common-heading">Elevate Your Event with DIGICAST Event Management.</h1>
                    <p className="service-common-subtitle">
                        Unforgettable Events, Flawless Execution.
                    </p>
                    <p className="service-common-subtitle">
                        At DIGICAST, we don’t just manage events—we create experiences. From innovative stage design to seamless branding and flawless production, we handle every detail with precision, ensuring your event leaves a lasting impression.
                    </p>
                    <section className="common-main-section">
                        <div className="common-float-left">
                            <div className="common-img-services service-common-border">
                                <img src={live} />
                            </div>
                            <div className="common-img-services m-t-20 service-common-border">
                                <img src={event} />
                            </div>
                        </div>
                        <div className="common-float-left">
                            <p className="service-common-subtitle p-tb-0">
                                <strong>Why Choose DIGICAST?</strong>
                            </p>
                            <ul className="list-data-common">
                                {
                                    listItem.map((list) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    list.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                            <p className="service-common-subtitle p-b-0">
                                <strong>
                                    Our Event Management Services:
                                </strong>
                            </p>
                            <ul className="list-data-common">

                                {
                                    subListItem.map((lists) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    lists.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>

                        </div>
                    </section>
                    <p className="service-common-subtitle">
                        <strong>
                            Ready to Make Your Event Unforgettable?
                        </strong>
                    </p>
                    <p className="service-common-subtitle">
                        Partner with DIGICAST to take your event to the next level. Whether it’s a corporate function, concert, or any special occasion, we’re here to ensure everything from the stage to the final production is handled with creativity and professionalism.
                    </p>
                    <p className="service-common-subtitle">
                        Contact us today to bring your vision to life with DIGICAST!
                    </p>

                </div>
                <div className="service-padding-section">
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default LiveEvent