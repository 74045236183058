import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import '../../ServicesPage/services.css'
import Broadcast from '../../../assests/images/imglist/Broadcast.jpg'
import Broadcast2 from '../../../assests/images/imglist/2.png'
const Brordcast = () => {
    const listItem = [

        { label: 'Multistream to All Platforms Simultaneously: Whether it’s Facebook, YouTube, Instagram, LinkedIn, or Twitch, we broadcast to multiple platforms simultaneously, maximizing your reach and ensuring no viewer is left out.' },
        { label: 'Engage Your Audience: Make your viewers feel special by featuring their live comments on screen, creating real-time two-way interactions that enhance engagement and leave a lasting impression.' },
        { label: 'Record-Breaking Reach: Our expertise in live streaming helped achieve a reach of 4.6 million during a single event. We’ve delivered streams across politics, sports, e-gaming, corporate, and entertainment sectors, bringing events to millions of viewers globally.' },
        { label: 'Whether it`s a smartphone, tablet, or smart TV, we ensure your channel is accessible worldwide, and if you want your devices perfectly synchronized—no delays or lags—we specialize in that, too.' }
    ]
    const subListItem = [

        { label: 'Professional Setup: We ensure a perfect combination of camera, audio, and technical equipment to deliver polished, high-quality streams for any event.' },
        { label: 'Advanced Broadcasting Tools: With the power of StreamYard and physical encoders like Zixi, Clearcaster, LiveU, and TVU,we ensure smooth and uninterrupted streaming across platforms.' },
        { label: 'Protocols & Customization: Using protocols like SRT, RTMP, and RTP, we tailor our streams to meet the specific technical requirements of each platform, delivering multiple bitrates for optimal performance.' },
        { label: 'Redundancy & Backup Plans: We prioritize reliability with full redundancy planning, backup streams, and delayed feeds if required, ensuring your stream is always on, no matter the situation.' },
        { label: 'Global & Local Broadcasting: Whether it’s a custom feed for international broadcasters or a delayed stream for global time zones, we’ve got you covered.' }
    ]

    return (
        <div>
            <Header />
            <div className="common-m-t">
            </div>
            <section className="service-common-bg common-padding">
                <div className="common-width">
                    <h1 className="service-common-title common-heading ">Elevate Every Moment with DIGICAST’s Seamless Live Streaming.</h1>
                    <p className="service-common-subtitle">
                        Unlock the power of professional live streaming for Product Launches, Political Rally, interviews, live podcasts, announcements, and more with DIGICAST’s advanced streaming solutions. We bring your content to life in real time, engaging audiences across platforms like Facebook, YouTube, Instagram, Twitter (X), and OTT services—all at once.
                    </p>
                    <section className="common-main-section">
                        <div className="common-float-left">
                            <div className="common-img-services service-common-border">
                                <img src={Broadcast} />
                            </div>
                            <div className="common-img-services m-t-20 service-common-border">
                                <img src={Broadcast2} />
                            </div>
                        </div>
                        <div className="common-float-left">
                            <p className="service-common-subtitle p-tb-0">
                                <strong>Why Choose DIGICAST?</strong>
                            </p>
                            <ul className="list-data-common">
                                {
                                    listItem.map((list) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    list.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                            <p className="service-common-subtitle p-b-0">
                                <strong>
                                    Cutting-Edge Technology for Flawless Streaming :
                                </strong>
                            </p>
                            <ul className="list-data-common">

                                {
                                    subListItem.map((lists) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    lists.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>

                        </div>
                    </section>
                    <p className="service-common-subtitle">
                        <strong>
                            Ready to Broadcast Your Next Event?
                        </strong>
                    </p>
                    <p className="service-common-subtitle">
                        From political campaigns to corporate launches, sports events, and entertainment, DIGICAST has the expertise to handle every live streaming need. With streams delivered to over 30 platforms simultaneously, we ensure your message reaches your audience wherever they are.
                    </p>
                    <p className="service-common-subtitle">
                        Take your event global with DIGICAST’s professional live streaming services. Contact us today and experience streaming excellence!
                    </p>

                </div>
                <div className="service-padding-section">
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Brordcast