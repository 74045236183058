import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import '../../ServicesPage/services.css'
import SocialMediaImg from '../../../assests/images/imglist/G.jpg'
import SocialMediaImg1 from '../../../assests/images/servicePage/socail1.jpeg'
import SocialMediaImg2 from '../../../assests/images/servicePage/socail2.jpeg'
const SocialMedia = () => {
    const listItem = [

        { label: 'Platform Mastery: From Facebook and Instagram to LinkedIn and TikTok, we know exactly how to leverage each platform’s unique features to drive maximum engagement and reach.' },
        { label: 'Compelling Content That Converts: Our creative team crafts eye-catching visuals, engaging videos, and share-worthy stories that not only grab attention but spark meaningful interactions.' },
        { label: 'Data-Driven, Results-Obsessed: We don’t guess—we analyze. Using cutting-edge analytics, we continuously optimize your campaigns for the best possible performance, ensuring your brand stays ahead of the competition.' },
        { label: 'Building Loyal Communities: We foster genuine connections between your brand and your audience, turning followers into loyal advocates through consistent, responsive engagement.' },
        { label: 'Integrated Digital Strategies: Social media is just the beginning. We seamlessly integrate SEO, email marketing, and more into your social campaigns, creating a cohesive and powerful brand message.' }
    ]
    const subListItem = [

        { label: 'Tailored Social Media Strategy' },
        { label: 'Creative Content Creation & Curation' },
        { label: 'Targeted Paid Advertising Management' },
        { label: 'In-Depth Performance Analytics' },
        { label: 'Engaged Community Management' }
    ]


    return (
        <div>
            <Header />
            <div className="common-m-t">
            </div>
            <section className="service-common-bg common-padding">
                <div className="common-width">
                    <h1 className="service-common-title common-heading">Unlock Your Brand's Potential with DIGICAST  Social Media Management</h1>
                    <p className="service-common-subtitle">
                        In today’s fast-paced digital world, standing out on social media is the key to success. At DIGICAST, we don’t just manage your platforms—we transform your brand’s online presence with strategies that captivate your audience, drive engagement, and boost visibility.
                    </p>
                    <section className="common-main-section">
                        <div className="common-float-left">
                            <div className="common-img-services service-common-border">
                                <img src={SocialMediaImg1} />
                            </div>
                            <div className="common-img-services m-t-20 service-common-border">
                                <img src={SocialMediaImg2} />
                            </div>
                        </div>
                        <div className="common-float-left">
                            <p className="service-common-subtitle p-tb-0">
                                <strong>Why Choose DIGICAST?</strong>
                            </p>
                            <ul className="list-data-common">
                                {
                                    listItem.map((list) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    list.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                            <p className="service-common-subtitle p-b-0">
                                <strong>
                                    Our Services :
                                </strong>
                            </p>
                            <ul className="list-data-common">

                                {
                                    subListItem.map((lists) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    lists.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>

                        </div>
                    </section>
                    <p className="service-common-subtitle">
                        <strong>
                            Ready to Transform Your Social Media Presence?
                        </strong>
                    </p>
                    <p className="service-common-subtitle">
                        Partner with DIGICAST  and take control of your brand’s digital narrative. Together, we’ll create social media strategies that not only boost your visibility but strengthen your connection with your audience. Reach out today and let’s make your brand unforgettable.
                    </p>

                </div>
                <div className="service-padding-section">
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default SocialMedia