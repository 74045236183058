import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AboutCompany from "../AboutCompany/AboutCompany";
import './about.css'
import about1 from  '../../assests/images/about1.jpeg'
import about2 from  '../../assests/images/about2.jpeg'
import MutipleSlidesPerView from "../Sliders/MutipleSlidesPerView";



const About = ()=>{
    return(
        <div className="about-bg-color"> 
            <Header />
            <div className="common-m-t">
            </div>
            <div>
                <section className="common-bgcolor">
                    <div className="common-width">
                            <AboutCompany />
                    </div>
               </section>
               <section className="common-bgcolor">
                    <section className="common-width">
                        <h1 className="subtitle"></h1>
                            <div className="clearfixed ">
                                <div className="about-float">
                                    <div className="about-img">
                                        <img src={about1}  />
                                    </div>
                                </div>
                                <div className="about-float about-border">
                                    
                                    <p className="about-subtitle">
                                        Our management team, consisting of experts in Marketing, Visual Communication, Audio-Visual Production, Creative Visualization, Project Management, Event Production, Broadcasting, and Streaming, has extensive experience in planning and executing large-scale projects with great success. We have been fortunate to receive support from our esteemed partners and collaborators and have played a key role in the successful completion of numerous prestigious projects.
                                        </p>
                                        <p className="about-subtitle">
                                        Furthermore, our partnerships have been invaluable to our success. We have established strong relationships with reputable organizations, vendor partners, and professionals across various industries, allowing us to leverage their expertise, resources, and networks to deliver outstanding results.
                                        </p>
                                        
                                        <p className="about-subtitle">
                                        In today’s fast-paced world, we recognize the power of visual communication. We firmly believe that impactful messages, strong brand awareness, and engaging target audiences are achieved through effective visual communication. 
                                        </p>
                                </div>
                            </div>
                    </section>
               </section>
             
               {/* slider */}
               <section className="bg-white-color"> 
                    <section className="common-width">
                        <h1 className="subtitle-color-black" >Our Client </h1>
                        <MutipleSlidesPerView />
                    </section>
               </section>
           </div>
           <Footer />
        </div>
    )
}

export default About;