import logo from './logo.svg';
import './App.css';
import Router from './Router_pages/Router';
import { StyledEngineProvider } from '@mui/material/styles';
import GoTopButton from './Components/GoTop/GoTop';

function App() {
  return (
    <div className="">
       <GoTopButton />
      <StyledEngineProvider injectFirst>
          <Router />
         
     </StyledEngineProvider>
    
    </div>
  );
}

export default App;
