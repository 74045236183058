import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import logo from  '../../assests/images/logo1.png'
import './header.css'
function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleDrawerToggle = () => {
   setMobileOpen((prevState) => !prevState);
 };

  return (
        <div>
           <AppBar position="fixed">
                <div className='display-none' >
                       <section className='display-property'>
                              <div>
                                 <Link to="/">
                                    <img src={logo}  className='logo-size'/> 
                                 </Link>
                              </div>
                              <div onClick={handleDrawerToggle}>
                                 <MenuIcon  className='menubar-color' />
                              </div>
                       </section>
                        
                  </div> 
               <ul className={mobileOpen? 'header-top display-none': 'header-top display-block'}>
                  <li id='logo'>
                     <div className='logo display-block'  >
                        <Link to="/">
                         <img src={logo}  id='logo-size'/> 
                        </Link>
                     </div>
                  </li>
                  <li className='heght-fixed'>
                     <Link to="/">Home</Link>
                  </li>
                  <li  className='heght-fixed'>
                     <Link to="/about">About</Link>
                  </li>
                  <li  className='heght-fixed'>
                     <Link to="/solutions">Solutions</Link>
                  </li>
                  <li  className='heght-fixed'>
                     <Link to="/contact">Contact</Link>
                  </li>
               </ul>
           </AppBar>
         </div>
  );
}
export default Header;
