import React from "react";
import './videoservices.css'
const itemData = [
    {
      title: 'Social Media Management',
    },
    {
      title: 'Broadcasting & Streaming',
    },
    {
      title: 'Event Production',
    },
    {
      title: 'Promotions and Amplification',
    },
    {
      title: 'Software Development',
    },
    {
      title: 'Innovative Solutions',
    },
    
  ];
const VideoServices = ()=>{
    return(
        <div>
            <div className="list-ul">
                <h1 className="page-title common-heading">Our video streaming services include</h1>
                <u>
                    {
                        itemData.map((list, index)=>{
                            return(
                                <li key={index} className="list-item animation-item">
                                   {list.title}
                                </li>
                            )
                        })
                    }
                </u>
           </div>
        </div>
    )
}

export default VideoServices;