import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import '../../ServicesPage/services.css'
import software1 from '../../../assests/images/servicePage/software1.jpeg'
import software2 from '../../../assests/images/servicePage/software2.jpg'

const SoftwareDevelopment = () => {
    const listItem = [

        { label: 'Custom Design: Tailored to your needs and built to impress.' },
        { label: 'User-Friendly & Responsive: Seamlessly optimized for every device.' },
        { label: 'SEO-Driven: Boosting your visibility and performance.' }
    ]
    const dataReport = [

        { label: 'In-Depth Analysis: Identify key insights from complex datasets.' },
        { label: 'Predictive Analytics: Anticipate trends and future opportunities.' }
    ]
    const interactive = [

        { label: 'Dynamic Dashboards: Real-time, customizable, and interactive.' },
        { label: 'Insightful Visuals: Make complex data easy to understand.' }
    ]
    const privacy = [

        { label: 'Data Privacy: Secure storage and GDPR-compliant management.' },
        { label: 'Risk Management: Minimize risks and ensure compliance.' }
    ]



    return (
        <div>
            <Header />
            <div className="common-m-t">
            </div>
            <section className="service-common-bg common-padding">
                <div className="common-width">
                    <h1 className="service-common-title common-heading">Unleash the Power of Data and Stunning Website Development with DIGICAST.</h1>
                    <p className="service-common-subtitle">
                        In today’s digital world, a strong online presence and data-driven insights are essential for business success. At DIGICAST, we offer top-tier website development, advanced data analytics, interactive data visualization, and machine learning solutions—ensuring your business thrives with robust data privacy.
                    </p>
                    <section className="common-main-section">
                        <div className="common-float-left">
                            <div className="common-img-services service-common-border">
                                <img src={software1} />
                            </div>
                            <div className="common-img-services m-t-20 service-common-border">
                                <img src={software2} />
                            </div>
                        </div>
                        <div className="common-float-left">
                            <p className="service-common-subtitle p-tb-0">
                                <strong>Why Choose DIGICAST?</strong>
                            </p>
                            <p className="service-common-subtitle common-p-s">
                                <strong>
                                    1-  Stunning Website Development
                                </strong>
                            </p>
                            <p className="service-common-subtitle common-p-s">
                                We design and build beautiful, responsive websites that captivate your audience and optimize user experience across all devices.
                            </p>
                            <ul className="list-data-common">
                                {
                                    listItem.map((list) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    list.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                            <p className="service-common-subtitle common-p-s">
                                <strong>
                                    2-  Data Analytics & Report Generation
                                </strong>
                            </p>
                            <p className="service-common-subtitle common-p-s">
                                Transform raw data into actionable insights with clear, customized reports that drive smarter decisions.
                            </p>
                            <ul className="list-data-common">

                                {
                                    dataReport.map((lists) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    lists.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>

                            <p className="service-common-subtitle common-p-s">
                                <strong>
                                    3-  Interactive Data Visualization
                                </strong>
                            </p>
                            <p className="service-common-subtitle common-p-s">
                                Communicate insights effectively with visually compelling dashboards and visuals.
                            </p>
                            <ul className="list-data-common">

                                {
                                    interactive.map((lists) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    lists.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>

                            <p className="service-common-subtitle common-p-s">
                                <strong>
                                    4-  Data Privacy & Security
                                </strong>
                            </p>
                            <p className="service-common-subtitle common-p-s">
                                We prioritize the protection of your data with top-tier privacy solutions and full compliance with global regulations.
                            </p>
                            <ul className="list-data-common">

                                {
                                    dataReport.map((lists) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    lists.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>



                        </div>
                    </section>
                    <p className="service-common-subtitle">
                        <strong>
                            Ready to Transform Your Business?
                        </strong>
                    </p>
                    <p className="service-common-subtitle">
                        Partner with DIGICAST to unlock stunning websites, powerful data insights, and secure digital growth. Contact us today!
                    </p>

                </div>
                <div className="service-padding-section">
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default SoftwareDevelopment