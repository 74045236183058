import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import '../../ServicesPage/services.css'
import sports from '../../../assests/images/imglist/6.png'
import game from '../../../assests/images/imglist/game.jpg'
const SportGaming = () => {
    const listItem = [

        { label: 'Mastering the Challenges of Live Streaming: With years of experience working with the biggest names in the industry, we ensure flawless streaming of high-speed, unpredictable action so your audience never misses a moment.' },
        { label: 'Professional Stream Production: Our expert production team delivers the perfect camera angles and optimized coverage, elevating your event to broadcast-level quality.' },
        { label: 'On-Site Video Encoding for Flawless Quality: We eliminate blurring, buffering, and lag with precise on-site encoding. Our high-performance encoders guarantee peak video quality, every time.' },
        { label: 'Rock-Solid Video Transmission: With bonded 4G, satellite, or fiber, we ensure uninterrupted, smooth streaming. Our technical expertise ensures reliable, consistent performance, no matter the event.' },
        { label: 'Stream Anywhere, On Any Device: DIGICAST streams are optimized for web, mobile, apps, and large digital screens—ensuring your audience can watch seamlessly on any device, anywhere.' },
        { label: '24/7 Monitoring & Real-Time Management: Our advanced monitoring center provides real-time oversight of every stream, giving you the confidence that every second of your event runs smoothly. We also extend monitoring services to other companies that need expert stream management.' }
    ]

    return (
        <div>
            <Header />
            <div className="common-m-t">
            </div>
            <section className="service-common-bg common-padding">
                <div className="common-width">
                    <h1 className="service-common-title common-heading">Experience Ultra-Low Latency HD Sports & Gaming Streaming with DIGICAST
                    </h1>
                    <p className="service-common-subtitle">
                        <strong>Where Every Second Thrills.</strong>
                    </p>
                    <p className="service-common-subtitle">
                        At DIGICAST, we’ve perfected the art of live sports and gaming streaming. Our secret? Delivering ultra-low latency, high-definition video that captures every heart-pounding second—because in live sports and gaming, timing is everything
                    </p>
                    <section className="common-main-section">
                        <div className="common-float-left">
                            <div className="common-img-services service-common-border">
                                <img src={sports} />
                            </div>
                            <div className="common-img-services m-t-20 service-common-border">
                                <img src={game} />
                            </div>
                        </div>
                        <div className="common-float-left">
                            <p className="service-common-subtitle p-tb-0">
                                <strong>Why Choose DIGICAST?</strong>
                            </p>
                            <ul className="list-data-common">
                                {
                                    listItem.map((list) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    list.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                    </section>
                    <p className="service-common-subtitle">
                        <strong>
                            Ready to Elevate Your Event?
                        </strong>
                    </p>
                    <p className="service-common-subtitle">
                        From start to finish, DIGICAST ensures that your live sports and gaming events are streamed seamlessly, delivering ultra-low latency and flawless precision. Let us take your event to the next level.
                    </p>
                    <p className="service-common-subtitle">
                        Contact us today to experience world-class streaming like never before!
                    </p>

                </div>
                <div className="service-padding-section">
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default SportGaming