import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Components/Home/Home";
import About from "../Components/About/About";
import Contact from "../Components/Contact/Contact";
import Solutions from "../Components/Solutions/Solutions";
import Privacy from "../Components/Privacy/Privacy";
import Terms from "../Components/Terms/Terms";
import Cookies from "../Components/Cookies/Cookies";
import SocialMedia from "../Components/ServicesPage/SocialMedia/SocialMedia";
import Drone from "../Components/ServicesPage/Drone/Drone";
import Brordcast from "../Components/ServicesPage/Brordcast/Brordcast";
import SportGaming from "../Components/ServicesPage/SportGaming/SportGaming";
import SoftwareDevelopment from "../Components/ServicesPage/SoftwareDevelopment/SoftwareDevelopment";
import LiveEvent from "../Components/ServicesPage/LiveEvent/LiveEvent";


const Router = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    {/* header */}
                    <Route path="/" element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="solutions" element={<Solutions />} />
                    <Route path="contact" element={<Contact />} />
                    {/* footer page */}
                    <Route path="privacy-policy" element={<Privacy />} />
                    <Route path="terms-conditions" element={<Terms />} />
                    <Route path="cookies" element={<Cookies />} />
                    {/* Solutions pages */}
                    <Route path="broadcast" element={<Brordcast />} />
                    <Route path="drone" element={<Drone />} />
                    <Route path="social-media" element={<SocialMedia />} />
                    <Route path="sport-gaming" element={<SportGaming />} />
                    <Route path="software-sevelopment" element={<SoftwareDevelopment />} />
                    <Route path="live-event" element={<LiveEvent />} />

                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Router;