import * as React from 'react';
import { Link } from 'react-router-dom';
import './footer.css'
const  Footer = () =>{

  return (
        <div className='footer-text'>
         <ul className='footer-bottom'>
            <li className=''>
               <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li  className=''>
               <Link to="/terms-conditions">Terms & conditions</Link>
            </li>
            <li  className=''>
               <Link to="/cookies">Cookies</Link>
            </li>
            <li className='' style={{color:'white'}}>
              © Digicast Solutions 2024. <br />
              Office Location: Mumbai, Delhi NCR, Bangloare

            </li>
            <li  className=''>
               <a href="https://www.facebook.com/digicastsolution" target='_blank' class="fa fa-facebook"></a>
               <a href="https://x.com/DigicastS" target='_blank' class="fa fa-twitter"></a>
               <a href="https://in.linkedin.com/company/digicasts" target='_blank' class="fa fa-linkedin"></a>
               <a href="https://youtube.com/@digicastsolutions4151" target='_blank' class="fa fa-youtube"></a>
               <a href="https://www.instagram.com/digicastsolution" target='_blank' class="fa fa-instagram"></a>
            </li>
            <li className='text-align-right'>Website designed by Mr Ram Bhardwaj :(2024)</li>
         </ul>
         </div>
  );
}
export default Footer;
