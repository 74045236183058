import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import '../../ServicesPage/services.css'
import invovation from '../../../assests/images/imglist/invovation.jpg'
import drone from '../../../assests/images/imglist/drone.jpeg'
const Drone = () => {
    const listItem = [
        {
            label: 'Stunning Aerial Views That Wow: Our advanced drones deliver jaw-dropping aerial footage in 4K, 2K, or Full HD, giving your audience a perspective that takes their breath away.'
        },
        {
            label: '360° Immersive Experiences: Take your viewers straight into the heart of the action. With our high-tech 360° cameras, every corner of your event is captured, creating an immersive experience that makes them feel like they re right there with you.'
        },
        {
            label: 'Unrivaled Professionalism, Seamless Execution: From flawless real-time editing to crystal-clear live replays, our seasoned team ensures your live stream is nothing short of perfect. We handle everything, so you can focus on enjoying your event.'
        },
        {
            label: 'Tailored, Bespoke Solutions: Your event is unique, and so are our solutions. We work closely with you to craft a custom approach that not only meets but exceeds your expectations, leaving a lasting impression on your audience.'
        }
    ]
    const subListItem = [

        {
            label: 'Concerts – Bring fans closer than ever with live, dynamic 360° streaming'
        },
        {
            label: 'Product Launches – Captivate your audience with bold, creative visuals'
        },
        {
            label: 'Sporting Events – Deliver every thrilling moment from every angle'
        },
        {
            label: 'Real Estate Showcases – Highlight properties with unique aerial and 360° perspectives'
        },
    ]


    return (
        <div>
            <Header />
            <div className="common-m-t">
            </div>
            <section className="service-common-bg common-padding">
                <div className="common-width">
                    <h1 className="service-common-title common-heading">Turn Every Moment into Magic with DIGICAST Aerial and 360° Live Streaming</h1>
                    <p className="service-common-subtitle">
                        Transform your event into an extraordinary experience with DIGICAST's cutting-edge drone and immersive 360° live streaming services. Whether it's a wedding, a concert, or a high-profile corporate event, our expert team captures every moment in breathtaking detail, ensuring your event is not just seen but truly felt.
                    </p>
                    <section className="common-main-section">
                        <div className="common-float-left">
                            <div className="common-img-services service-common-border">
                                <img src={drone} />
                                {/* <div className="video-section">
                                    <video autoPlay  muted loop>
                                        <source src={drone} type="video/mp4"  />
                                    </video>
                                </div>
                                */}
                            </div>
                            <div className="common-img-services m-t-20 service-common-border">
                                <img src={invovation} />
                            </div>
                        </div>
                        <div className="common-float-left">
                            <p className="service-common-subtitle p-tb-0">
                                <strong>Why Choose DIGICAST?</strong>
                            </p>
                            <ul className="list-data-common">
                                {
                                    listItem.map((list) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    list.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                            <p className="service-common-subtitle p-b-0">
                                <strong>
                                    Perfect for:
                                </strong>
                            </p>
                            <ul className="list-data-common">

                                {
                                    subListItem.map((lists) => {
                                        return (
                                            <li className="service-common-subtitle">
                                                {
                                                    lists.label
                                                }
                                            </li>
                                        )
                                    })
                                }

                            </ul>

                        </div>
                    </section>
                    <p className="service-common-subtitle">
                        DIGICAST turns ordinary events into extraordinary experiences. Let us capture your moments with the power and precision that only the best in the industry can provide. When you choose DIGICAST, you're choosing to make
                        an impact that lasts long after the event is over.
                    </p>

                </div>
                <div className="service-padding-section">
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Drone