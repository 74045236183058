import React  from "react" 
import ScrollToTop from "react-scroll-to-top";
const GoTopButton = () => {
return (
  
    <div>
        <div style={{ marginTop: "10vh", backgroundColor:'red' }} />
        <ScrollToTop smooth color="white" className="gototop"/>
    </div>
   
)

}
    export default GoTopButton