import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Terms = () =>{
    return(
        <div>
          <Header/>
          <div className="common-m-t">
            </div>
           <section className="common-bgcolor common-padding">
                <div className="common-width">
                    <h1 className="page-title">Terms and Conditions for Broadcasting</h1>
                    <p className="about-subtitle">
                   Accessing or using DigiCast ’s broadcasting services, you agree to these Terms and Conditions. If you do not agree, please do not use our services.
                    </p>
                    <p className="about-subtitle">
                    Users may need to create an account to access certain features. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
                    <p className="about-subtitle">
                    You agree to use our services only for lawful purposes. You will not engage in any activity that could harm, disrupt, or interfere with our services or other users.
                    </p>
                    <p className="about-subtitle">
                    All content provided through our services is owned by DigiCast  or our content providers. You may not reproduce, distribute, or create derivative works without permission.
                    </p>
                    <p className="about-subtitle">
                    We implement reasonable security measures to protect your information from unauthorized access and disclosure. However, no method of transmission over the internet is 100% secure.
                    </p>
                    <p className="about-subtitle">
                    ou have the right to access, correct, or delete your personal information. To exercise these rights, please contact us at Mumbai Location.
                    </p>
                    <p className="about-subtitle">
                    Our services are provided “as is” and “as available.” We do not guarantee that our services will be uninterrupted or error-free.
                    </p>
                    <p className="about-subtitle">
                    If you submit any content (e.g., comments, reviews), you grant DigiCast  a non-exclusive, royalty-free, perpetual license to use, modify, and display that content.
                    </p>
                </div>
                <div className="padding-section">
                </div>
           </section>
            <Footer />
        </div>
    )
}

export default Terms