import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ImagesList from "../ImagesList/ImagesList";
import MutipleSlidesPerView from "../Sliders/MutipleSlidesPerView";

const Services = ()=>{
    return(
        <div>
            <Header />
            <div className="common-m-t">
            </div>
            <div>
            <section className="bg-img-text ">
               <div className="common-width">
               <h1 className="subtitle common-heading" style={{color:'#000000'}}>Our Solutions</h1>
                    <ImagesList />
               </div>
           </section>
           <section className="bg-white-color"> 
                <section className="common-width">
                    <h1  className="subtitle-color-black common-heading" >Our Client </h1>
                    <MutipleSlidesPerView />
                </section>
           </section>
           </div>
           <Footer />
        </div>
    )
}

export default Services;