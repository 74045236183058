import  React, {useState} from 'react';
import './imglist.css'
import camera from  '../../assests/images/images1.jpg'
import Broadcast from '../../assests/images/imglist/Broadcast.jpg'
import socialMedia from '../../assests/images/imglist/G.jpg'
import sports from '../../assests/images/imglist/6.png'
import D from '../../assests/images/imglist/K.jpg'
import E from '../../assests/images/imglist/modi.JPG'
import F from '../../assests/images/imglist/atwuysqixdok_m.jpg'
import live from '../../assests/images/imglist/D.jpg'
import software from '../../assests/images/imglist/software.jpeg'
import invovation from '../../assests/images/imglist/invovation.jpg'
import drone from '../../assests/images/imglist/invovation.jpg'
import { Link } from 'react-router-dom';


const itemData = [
    { 
      id:1,
      img: Broadcast,
      title: 'Broadcast & Streaming',
      path:'/broadcast'
    },
    {
      id:2,
      img: sports,
      title: 'Sports & Gaming',
       path:'/sport-gaming'
    },
    {
      id:3,
      img: invovation,
      title: 'Drone and 360` Streaming ',
      path:'/drone'
      
    },
  
    {
      id:4,
      img: live,
      title: 'Live Events & Shows',
      path:'/live-event'
    },
    {
      id:5,
      img: socialMedia,
      title: 'Social Media Management',
      path:'/social-media'
    },
    {
      id:6,
      img: software,
      title: 'Software Development',
      path:'/software-sevelopment'
    },
    
    
  ];




 const  ImagesList = () => {
  
  return (
      <section>
          <div className='list-img-section clearfixed'>
             
              {
                 itemData.map((list)=>{
                    return(
                        <div key={list.id}  className='float-left-img'>
                            <Link to={list.path} >
                                <div className='img-width'>
                                  <div className='img-title' >
                                    <img src={list.img} className='fade-img'/>
                                      <h2 className='img-subtitle'>{list.title}</h2>  
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                 })
              }
          </div>
      </section>
  );
}

export default ImagesList