import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Privacy = () =>{
    return(
        <div>
          <Header/>
          <div className="common-m-t">
            </div>
           <section className="common-bgcolor common-padding">
                <div className="common-width">
                    <h1 className="page-title">Privacy Policy for Broadcasting</h1>
                    <p className="about-subtitle">
                    This privacy policy explains how Digicast collects, uses, and protects personal information in connection with our broadcasting services.
                    </p>
                    <p className="about-subtitle">
                    Personal Information: We may collect personal information, such as names, email addresses, and phone numbers when you subscribe to our services or participate in our broadcasts.
                    Usage Data: We automatically collect information about how you use our services, including IP addresses, device information, and viewing habits.
                    </p>
                    <p className="about-subtitle">
                    To provide and improve our broadcasting services.
                        To communicate with you, including sending updates and promotional materials.
                        To analyze usage and trends to enhance user experience.
                    </p>
                    <p className="about-subtitle">
                    We do not sell or rent your personal information to third parties.
We may share your information with trusted service providers who assist us in delivering our services, subject to confidentiality agreements.
We may disclose your information if required by law or to protect our rights.
                    </p>
                    <p className="about-subtitle">
                    We implement reasonable security measures to protect your information from unauthorized access and disclosure. However, no method of transmission over the internet is 100% secure.
                    </p>
                    <p className="about-subtitle">
                    ou have the right to access, correct, or delete your personal information. To exercise these rights, please contact us at Mumbai Location.
                    </p>
                    <p className="about-subtitle">
                    We may update this privacy policy from time to time. We will notify you of any significant changes through our broadcasting channels or via email.
                    </p>
                    <p className="about-subtitle">
                    If you have any questions about this privacy policy, please contact us at Mumbai Location
                    </p>
                </div>
                <div className="padding-section">
                </div>
           </section>
            <Footer />
        </div>
    )
}

export default Privacy