import React from "react";
import Header from "../Header/Header";
import './home.css'
import ImagesList from "../ImagesList/ImagesList";
import Footer from "../Footer/Footer";
import AboutCompany from "../AboutCompany/AboutCompany";
import VideoServices from "../VideoServices/VideoServices";
import GoTop from "../GoTop/GoTop";
import MutipleSlidesPerView from "../Sliders/MutipleSlidesPerView";

import homeVideo1 from '../../assests/video/home.mp4'
const Home = () => {
    return (
        <div>
            <Header />
            <div className="home-common-m-t">
            </div>
            <section className="video-section" id="section-1">
                <video autoPlay muted loop className="bg-vid">
                    <source src={homeVideo1} type="video/mp4" />
                </video>
                <div className="video-bg">
                </div>
                <h1 className="video-text">
                    <bold> Your Event, Your Stream, Your Way</bold>
                </h1>
            </section>
            <section className="home-bgcolor-white">
                <div className="common-width">
                    <h1 className="home-page-title common-heading">Our Solutions</h1>
                    <ImagesList />
                </div>
            </section>
            <section className="about-section">
                <div className="common-width">
                    <AboutCompany />
                </div>
            </section>
            <section className="bg-white-color">
                <div className="common-width">
                    <h1 className="subtitle-color-black common-heading">Our Client</h1>
                    <MutipleSlidesPerView />
                </div>
            </section>

            <section className="about-section">
                <div className="common-width">
                    <VideoServices />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Home;